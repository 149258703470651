import React, {useState} from 'react';
import '../styles/main.scss';
import SparkleImage from '../Assets/icons8-sparkle-32.png';

function ComingSoon({ backgroundImage }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Prevents double submission

  const handleSubmit = async (e) => {
      e.preventDefault(); // Prevents the default form behavior

      if (isSubmitting) return; // Stops extra clicks
      setIsSubmitting(true); // Prevents further submissions

      try {
        const response = await fetch('https://sunsetave-backend.onrender.com/api/newsletter/subscribe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) { // ✅ Handles failed responses
          throw new Error("Failed to subscribe");
        }

        const data = await response.json();
        setMessage(data.message); // Show success message
        setEmail(''); // Clear input field
      } catch (error) {
        console.error("Subscription error:", error);
        setMessage("Something went wrong. Please try again.");
      } finally {
        setIsSubmitting(false); // ✅ Ensure this only happens after request completes
      }
  };
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">
        <img src={SparkleImage} className="sparkle-icon sparkle-left" alt="Sparkle" />
          Coming Soon 
        <img src={SparkleImage} className="sparkle-icon sparkle-left" alt="Sparkle" />
      </h1>
      <p className="coming-soon-text">
        Sign up for our newsletter to be the first to know when new styles drop!
      </p>
      <form onSubmit={handleSubmit} className="newsletter-form">
        <input type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required />
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "SIGN UP"}
        </button>
     </form>
     {message && <p className="newsletter-message">{message}</p>}
    </div>
  );
}

export default ComingSoon;
