import React from 'react';
import ComingSoon from '../components/ComingSoon';

function Shop() {
    return (
        <>
        <section className="shop-page-banner">
            <section className="shop-page-banner-content">
                <h2>Shop</h2>
            </section>
        </section>
        <ComingSoon />
        </>
    );
  }
  

export default Shop;