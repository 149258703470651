import React, {useState} from 'react';
import ProductGrid from '../components/ProductGrid';
import '../styles/main.scss';
import SparkleImage from '../Assets/icons8-sparkle-32.png';

function New() {
  // Temporary placeholder products
  const placeholderProducts = Array.from({ length: 6 }, (_, index) => ({
    id: index + 1,
    name: 'Coming Soon',
    price: 'TBA',
    image: '' // Leave empty for now
  }));

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 3;

  // Slice products for the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentProducts = placeholderProducts.slice(0, currentPage * productsPerPage);

  // Handle View More
  const handleViewMore = () => {
    if (currentPage * productsPerPage < placeholderProducts.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Calculate total pages
  const totalPages = Math.ceil(placeholderProducts.length / productsPerPage);


  return (
    <section className="new-page">
      <section className="new-page-banner">
        <section className="new-page-banner-content">
          <h2>New Arrivals</h2>
        </section>
      </section>
      <section className="join-newsletter">
        <h1 className="coming-soon-title">
          <img src={SparkleImage} className="sparkle-icon sparkle-left" alt="Sparkle" />
            Coming Soon 
          <img src={SparkleImage} className="sparkle-icon sparkle-left" alt="Sparkle" />
        </h1>
        <p>Be the first to know when new styles drop by signing up for our newsletter below</p>
        {/* <form>
          <input type="email" placeholder="Enter your email" />
          <button type="submit">SIGN UP</button>
        </form> */}
      </section>

      <ProductGrid
        products={currentProducts}
        totalProducts={placeholderProducts.length}
        currentPage={currentPage}
        productsPerPage={productsPerPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
        onViewMore={handleViewMore}
      />

    </section>
  );
}

export default New;
