import React from 'react';
import '../styles/main.scss';
import ComingSoon from '../components/ComingSoon';


function Blog() {
    return (
        <ComingSoon />
    );
}

export default Blog;