import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './styles/main.scss';
import InstagramIcon from './Assets/icons8-instagram.svg';
import TikTokIcon from './Assets/icons8-tiktok.svg';
import PinterestIcon from './Assets/icons8-pinterest.svg';



function Footer() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Prevents double submission
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (e) => {
      e.preventDefault(); // Prevents the default form behavior

      if (isSubmitting) return; // Stops extra clicks
      setIsSubmitting(true); // Prevents further submissions

      try {
        const response = await fetch('https://sunsetave-backend.onrender.com/api/newsletter/subscribe', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) { // ✅ Handles failed responses
          throw new Error("Failed to subscribe");
        }

        const data = await response.json();
        setMessage(data.message); // Show success message
        setEmail(''); // Clear input field
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 3000);
      } catch (error) {
        console.error("Subscription error:", error);
        setMessage("Something went wrong. Please try again.");
      } finally {
        setIsSubmitting(false); // ✅ Ensure this only happens after request completes
      }
  };

  return (
    <footer className="footer">
      {/* First Column */}
      <div className="footer-column">
        <h3>Your One<span className="dash">-</span>Stop Summer Shop</h3>
        <p>Discover timeless, flowy styles perfect for every chic moment. Your dream summer closet starts here.</p>
        <div className="social-media">
          <a href="https://instagram.com/urvibeissunset" aria-label="Instagram">
            <img src={InstagramIcon} alt="Instagram" />
          </a>
          <a href="https://pinterest.com/urvibeissunset" aria-label="Pinterest">
            <img src={PinterestIcon} alt="Pinterest" />
          </a>
          <a href="https://tiktok.com/@shopsunsetave" aria-label="TikTok">
            <img src={TikTokIcon} alt="TikTok" />
          </a>
        </div>
      </div>

      {/* Second Column */}
      <div className="footer-column">
        <ul>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/reviews">Reviews</Link></li>
          <li><Link to="#terms">Terms & Conditions</Link></li>
          <li><Link to="#privacy">Privacy Policy</Link></li>
        </ul>
      </div>

      {/* Third Column */}
      <div className="footer-column">
        <h3>Sign Up for Our Newsletter</h3>
        <p>Be the first to know when new styles drop and elevate your wardrobe!</p>
        <form onSubmit={handleSubmit} className="newsletter-form">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "SIGN UP"}
          </button>
          {showPopup && message && (
            <span className="custom-tooltip success-tooltip">{message}</span>
          )}
        </form>
      </div>
    </footer> 
    );
}

export default Footer;
