import React from 'react';
import ComingSoon from '../components/ComingSoon';

function Dresses() {
  return (
    <>
      <section className="dresses-page-banner">
            <section className="dresses-page-banner-content">
                <h2>Dresses</h2>
            </section>
        </section>
      <ComingSoon />
    </>
  );
}

export default Dresses;
