import React, { useEffect, useState } from 'react';
import ReviewIcon from '../Assets/icons8-review-33.png';
import InstagramIcon from '../Assets/icons8-instagram.svg';
import TikTokIcon from '../Assets/icons8-tiktok.svg';
import PinterestIcon from '../Assets/icons8-pinterest.svg';

import '../styles/main.scss';


function About() {
    const [isVisible, setIsVisible] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        brandName: '',
        email: '',
        message: '',
    });
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [responseMsg, setResponseMsg] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const section = document.querySelector('.partners-section');
            if (section) {
                const rect = section.getBoundingClientRect();
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                if (rect.top <= windowHeight * 0.75) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setResponseMsg('');
        
        try {
            const res = await fetch('https://sunsetave-backend.onrender.com/api/brand/message', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
            });
        
            const data = await res.json();
            setResponseMsg(data.message || 'Message sent!');
            setFormData({ name: '', brandName: '', email: '', message: '' });
        } catch (err) {
            console.error(err);
            setResponseMsg('Something went wrong. Please try again.');
        }
        
        setIsSubmitting(false);

    };
      

    return (
        <>
        <section className="about-section">
            <div className="about-content">
                
                {/* Left Side: Image */}
                <div className="about-img">
                    <img src="/images/sunsetave_collage_2.jpg" alt="About SunsetAve" />
                </div>

                {/* Right Side: Text */}
                <div className="about-txt-content">
                    <h2 className="about-title">About SunsetAve.</h2>
                    <div className="quote-container">
                        <div className="quote-line"></div>
                        <blockquote className="founder-quote">
                            "Welcome to SunsetAve.   A collection of pieces I love — made to help you feel like your most effortless, beautiful self under the summer sun."
                            <span className="signature">— T.</span>
                        </blockquote>
                    </div>
                    <p className="mission-paragraph">
                        <span>Finding cute summer clothes should be easy</span> — and finding them all in one place should be even easier. That’s where SunsetAve comes in.
                    </p>
                    <p className="mission-paragraph">
                        SunsetAve is <span>your one-stop summer shop</span>, filled with my personal lineup of handpicked favorites — pieces that make you feel like you’re living out your perfect summer day, whether you’re beach-hopping, grabbing drinks, strolling through the park, or chasing golden hour.
                    </p>
                    <p className="mission-paragraph">
                        I started SunsetAve because I was tired of digging through countless sites just to find my style. I wanted one place that just got it — effortless, fun, and made for every kind of summer day. So I decided to bring my vision to life!
                    </p>
                    <p className="mission-paragraph">
                        Hope you find simething you love — <span>Your dream summer closet starts here...🌅</span>
                    </p>                    
                </div>
            </div>
        </section>
        {/* Partners Section */}
        <section className="partners-section">
            <div className={`partners-text ${isVisible ? 'visible' : ''}`}>
                {/* <h2>Our Future Partners</h2> */}
                <h2>✨ Stay tuned for the brands we’ll be working with ✨</h2>
            </div>
        </section>      
        <section className="brand-feature-form">
            <h3>Want your brand featured?</h3>
            <p>SunsetAve is built on unique finds from brands big and small — If you’re a brand that embodies the effortless summer style we love, let’s talk! Fill out the form below and we’ll be in touch.</p>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="brandName"
                    placeholder="Brand Name"
                    value={formData.brandName}
                    onChange={handleChange}
                    required
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <textarea
                    name="message"
                    placeholder="Tell us about your brand..."
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>

                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Sending...' : 'Send Message'}
                </button>

                {responseMsg && <p className="form-response">{responseMsg}</p>}
            </form>

        </section>

        <section className="faq-section">
            <h3>FAQ</h3>
            <div className="faq-item">
                <h4>How do I know if my brand fits SunsetAve?</h4>
                <p>We’re all about that dreamy summer aesthetic — breezy fits, soft hues, timeless elegance. If your brand embraces this, we’d love to hear from you!</p>
            </div>
            <div className="faq-item">
                <h4>How soon can I expect a response?</h4>
                <p>We’re a small but passionate team, so expect to hear back within 3-5 business days.</p>
            </div>
            <div className="faq-item">
                <h4>Can I suggest a brand I love?</h4>
                <p>Absolutely! DM us on Instagram <span id='p-bold'>@urvibeissunset</span> or leave us a review using this icon: <span><img src={ReviewIcon} alt="Review Icon" className="review-icon" /></span>, in the top right corner of the page! Let us know who you want to see. 
                </p>
            </div>
            <div className="faq-item">
                <h4>Where can you find us?</h4>
                <p>These are our only socials!</p>
                <p><img src={PinterestIcon} alt="Pinterest" /> <a href="https://instagram.com/urvibeissunset">@urvibeissunset</a>
                </p>
                <p><img src={InstagramIcon} alt="Instagram" /> <a href="https://tiktok.com/@shopsunsetave">@urvibeissunset</a>
                </p>
                <p><img src={TikTokIcon} alt="TikTok" /> <a href="https://pinterest.com/urvibeissunset">@shopsunsetave</a>
                </p>
            </div>
        </section>      

        </>
    );
}

export default About;