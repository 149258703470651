import React,  { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from './Assets/icons8-search.svg';
import ReviewIcon from './Assets/icons8-review-33.png';
import './styles/main.scss';


function Taskbar() {
    const [scrolled, setScrolled] = useState(false);
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    // Scroll listener for taskbar
    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`taskbar ${scrolled ? 'scrolled' : ''}`}>
        {/* Logo on the left */}
        <div className="logo-container">
            {!scrolled ? (
                <Link to="/">
                    <img src="/Logo.png" alt="SunsetAve Logo" className="logo" />
                </Link>
            ) : (
            <Link to="/" className="logo-text">
                <span className="sunset">Sunset</span>
                <span className="ave">A v e.</span>
            </Link>
            )}        
        </div>

        {/* Navigation Links */}
        <nav className="nav-links">
            <Link to="/new">NEW</Link>
            <Link to="/shop">SHOP</Link>
            <Link to="/dresses">DRESSES</Link>
            <Link to="/about">ABOUT</Link>
            <Link to="/blog">BLOG</Link>
        </nav>

        {/* Icons on the right */}
        <div className="icons">
            {/* Search Icon  */}
            <div className="search-container">
            <img 
                src={SearchIcon} 
                alt="Search Icon" 
                className="search-icon"  
                onClick={() => setIsSearchFocused(true)} 
            />
            <input 
                type="search" 
                className={`search-input ${isSearchFocused ? 'expanded' : ''}`} 
                placeholder="Search for styles..." 
                onBlur={() => setIsSearchFocused(false)}
            />
        </div>
            
            {/* Review Icon*/}
            <div className="review-container">
                <Link to="/reviews">
                    <img src={ReviewIcon} alt="Review Icon" className="review-icon" />
                    <span className="custom-tooltip">✨Leave us a review✨</span>
                </Link>
            </div>

        </div>
        </header>
  );
}

export default Taskbar;
