import React, { useState } from 'react';import '../styles/main.scss';
import SparkleImage from '../Assets/icons8-sparkle-32.png';

function ReviewPage() {
    const [review, setReview] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');

        const response = await fetch('https://sunsetave-backend.onrender.com/api/reviews/submit', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message: review })
        });

        const data = await response.json();
        if (response.ok) {
            setMessage('Review submitted successfully!');
            setReview('');
        } else {
            setMessage(data.error || 'Something went wrong');
        }
    };

  return (
    <div className="review-page-container">
      <h1 className="review-page-title">
        <img src={SparkleImage} className="sparkle-icon sparkle-left" alt="Sparkle" />
          Leave us a Review 
        <img src={SparkleImage} className="sparkle-icon sparkle-right" alt="Sparkle" />
      </h1>
      <p className="review-page-text">
        We’d love to hear from you! Tell us what you love, what you want to see, or even your favorite summer memory. Your feedback helps us grow SunsetAve into the dreamiest summer destination.
      </p>
      <form className="review-form" onSubmit={handleSubmit}>
        <textarea 
            name="message" 
            placeholder="Type your review here..." 
            rows="5" 
            value={review} 
            onChange={(e) => setReview(e.target.value)}
        />
        <button type="submit">Send Review</button>
    </form>
    {message && <p>{message}</p>}    
    
    </div>
  );
}

export default ReviewPage;
